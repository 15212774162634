
// import React, {Component} from 'react'
// import {Switch} from 'react-router-dom'
// import { withRouter } from 'react-router-dom';
// import all from '../all/routes'
// import LoggedOut from '../LoggedOut/component'
// import ScrollToTop from '../ScrollToTop/component'

import React, { Component } from "react";
import PropTypes from "prop-types";
import {connect} from 'react-redux'
// import {components} from '../all/components'
import Header from '../Header/component' 
import { GlobalStyle } from "../../global";
import {ThemeContext} from '../context/index'

import Home from '../Home/component'
import Privacy from '../Privacy/component'

import Error400 from '../Error400/component'




class ReduxFirstApp extends Component{

  constructor(props){

    super(props)
}
static contextType = ThemeContext
    static propTypes = {
        location: PropTypes.object
      };

   

    pages = {

        HOME: <Home />,
        PRIVACY: <Privacy />,
        ERROR400: <Error400 />,
        NOT_FOUND: <Error400 />

    }


    


    
    render(){
      console.log("THE THEMECONTEXT", this.context.them)
        const theme = this?.context?.theme

        console.log('THE LOCATION PROP')
        console.log(this.props.location)
        console.log(this.props.location.type) 
        console.log(this.props.location.type)
        console.log(this.pages.hasOwnProperty(this.props.location.type))
        return(

            <>
               
             <GlobalStyle theme={{background: theme.colors.complimentary.base, foreground: theme.colors.complimentary.foreground}} /> 
             <Header />
             {this.pages[this.props.location.type]}
    
               
           </>
        )
    }




}

const mapStateToProps = state => {
    return {
      location: state.location
    };
  };

export default connect(mapStateToProps)(ReduxFirstApp)

