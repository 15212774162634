import React, { Component } from 'react';
import {BrowserRouter as Router,Link} from 'react-router-dom';
import Icon from '@material-ui/core/Icon';
import Contacts from '@material-ui/icons/People';
import { withStyles } from '@material-ui/core/styles'
import Dropdown from '../Dropdown/dropdown'
import BannerAd from '../Bannerad/bannerad'
// import Navigation from "../../../Common/navigation/navigation"
// import links from './links'

const styles = {
    root: {
     
      color:"green",
      fontSize: 20,
      
    },
    iconhover:{
        '&:hover':{

            content: ""
        }
    }
  };




class Men extends Component{

    constructor(props){


        super(props)

        this.state = {
          location: [
            {
                id: 0,
                title: 'My account',
                selected: false,
                key: 'location',
                to: '/access'
            },
            {
              id: 1,
              title: 'Jobs',
              selected: false,
              key: 'location',
              to: '/jobs'
            },
            {
              id: 2,
              title: 'Post jobs',
              selected: false,
              key: 'location',
              to: '/postjob'

            },
            {
              id: 3,
              title: 'Job alert',
              selected: false,
              key: 'location',
              to: '/alert'
            },
            {
              id: 4,
              title: 'About',
              selected: false,
              key: 'location',
              to: '/about'
            },
            {
              id: 5,
              title: 'Blog',
              selected: false,
              key: 'location',
              to: '/blog'
            }
          ]
        }

      }



      toggleSelected(id, key){

        let temp = this.state[key]
        temp[id].selected = !temp[id].selected
        this.setState({
          [key]: temp
        })

      }

  
   render(){
    return(

        <div >


        

                
                    <section className={`bg-primary ${this.props.path === '/' ? 'menu-default': 'menu-custom'}`}>
                       

                        <Dropdown

                            
                            activator={true}
                            list={this.state.location}
                            toggleItem={this.toggleSelected.bind(this)}

                        />

                    </section>


                

     

            {this.props.path === '/' ? 
                <section className="job-type bx-raised-1">

                    <Link to="/jobs?jq=full-time&jxtp=jx"  className="menu-link link">
                        <span className="d-block text-align-center"><Icon><Contacts /></Icon></span>
                        <span className="d-block menu-text"> Full-time</span>
                    </Link>
                    <Link  to="/jobs?jq=part-time&jxtp=jx" className="menu-link link">
                        <span className="d-block text-align-center"><Icon><Contacts /></Icon></span>
                        <span className="d-block menu-text"> Part-time</span>
                    </Link>

                    <Link  to="/jobs?jq=freelance&jxtp=jx" className="menu-link link">
                        <span className="d-block text-align-center"><Icon><Contacts /></Icon></span>
                        <span className="d-block menu-text"> Freelance</span>
                    </Link>

                    <Link  to="/jobs?jq=internship&jxtp=jx" className="menu-link link">
                        <span className="d-block text-align-center"><Icon><Contacts /></Icon></span>
                        <span className="d-block menu-text"> Internship</span>
                    </Link>
                    <Link  to="/jobs?jq=remote&jxtp=jx" className="menu-link link">
                        <span className="d-block text-align-center"><Icon><Contacts /></Icon></span>
                        <span className="d-block menu-text"> Remote</span>
                    </Link>
                    <Link  to="/jobs?jq=commute&jxtp=jx" className="menu-link link">
                        <span className="d-block text-align-center"><Icon><Contacts /></Icon></span>
                        <span className="d-block menu-text"> Commute</span>
                    </Link>

                </section>
                : null

                }

                {

                  this.props.path === '/jobs'
                    ? <BannerAd />
                    : null
                }

        </div>
    )
  }
}

export default withStyles(styles)(Men)