
import React, {Component} from 'react' 
import { bindActionCreators } from 'redux'
import {connect} from 'react-redux'
import * as actions from './actions'
import {ThemeSwitcher} from "../ThemeSwitcher/index"


import Jobdash from './jobdash/jobdash'
import Shapes from './shapes/shapes'
import Soon from './soon/soon'
import Hero from './hero/hero' 
import Footer from './footer/footer'




class Home extends Component{


    // constructor(props){

    //     super(props)
    // }

    constructor(props){

        super()
        this.state = {

            scrolled: false,
            width: 0,
            height: 0,
            //headerClasses : ['header__offset']
          
        }
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidUpdate(){

        const {home} = this.props
        const {isFetching,isProgressBar} = home
    
        if(isProgressBar === true && isFetching === true){
    
          document.body.style.overflow = 'hidden'
          document.body.style.pointerEvents ='none'
          document.body.style.opacity = '0.4'
    
        }else{
    
          document.body.style.overflow = 'visible'
          document.body.style.pointerEvents ='all'
          document.body.style.opacity = '1'
        }
       
      }

      updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
      }

    componentDidMount(){

        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        //window.addEventListener('scroll',this.handleScroll)
    }

    componentWillUnmount(){

        window.removeEventListener('resize', this.updateWindowDimensions);
        //window.removeEventListener('scroll',this.handleScroll) 
     }


    render(){
       
        const {home,actions} = this.props
        const {width} = this.state 
        const switcherStyles = width <= 900 ? { position: "absolute", top: "-35px", right: "10px", cursor: "pointer", zIndex: 10 } : { position: "fixed", top: "-25px", right: "60px", cursor: "pointer",zIndex:10 }
        // const {isFetching,isProgressBar} = home
    
        return(

       

                <article className="home">

                    <span style={switcherStyles}>
                        <ThemeSwitcher />

                    </span>
                    <Shapes />
                    <Soon  actions={actions} home={home} />
                    <Hero width={width} />
                    <Jobdash width={width} />
                    <Footer />
            

                    {/* <div className="mg-bottom-fd-hg">

                        
                        <Search />
                     
                        <strong className="clearfix" />
                       
                       
                    </div> */}
                    {/* <div>
                        <Identity />
                    </div>

                    <div>
                        <Jobseeker />
                    </div>
                    <div>
                        <Jobdash />
                    </div>
                     */}

                </article>
                
           
        )
    }




}

const mapStateToProps = (state)=>{

    const {home,router} = state

    return{
        home,router
    }

   
}

const mapDispachToProps = (dispatch)=>{

    return {

        actions: bindActionCreators({...actions},dispatch)
    }
}

export default connect(mapStateToProps,mapDispachToProps)(Home)