
import React,{Component} from 'react'
import { ThemeContext } from '../../context'



class Hero extends Component{
    
   constructor(props){
    super(props)
   }
    
    static contextType = ThemeContext
    render(){

        const {width=0} = this.props
        const {theme, themeName} = this.context
    const boxesStyling = { backgroundColor: themeName == 'dark' 
    ? theme.colors.complimentary['accent-3'] 
    :  theme.colors.complimentary.base }
    const boxesStylingA = {backgroundColor: theme.colors.complimentary.foreground}
    const boxesStylingAccent = {backgroundColor: theme.colors.complimentary.base}
    const textColorStyles = {color:  theme.colors.complimentary.foreground}
    const textColorStylesAccent = {color: themeName == 'dark' ? theme.colors.complimentary['accent-2'] : theme.colors.complimentary.foreground}
    const heroModStyles = width < 1200 ? {zIndex:1} : {zIndex:1,top:65}
       

        return(

            <div className="home__hero" style={heroModStyles}>

               <section className="home__parallelogram bx-raised" style={boxesStyling}>

                  <div className="home__parallelogram--skew">
                   <div className="home__parallelogram--h">
                        <h2 style={textColorStyles}>Jobbri is a job search engine launching soon.</h2>
                        <span></span>
                    </div> 

                    <div className="home__parallelogram--q">
                        <h2>Are you job-hunting or pehaps looking to hire talent?</h2>

                    </div>
                    <div className="home__parallelogram--txt" style={textColorStyles}>
                        <p>Reach out to Jobbri, a start-up job search engine whose goal is to help you find desired jobs or talent easily.</p>
                        <p>We are a local listing job search engine, and one of
                            our goals is to collect all the jobs and bring them to you in one place.
                        </p>
                    </div>

                    </div>


               </section>
            </div>
        )
    }

}


export default Hero