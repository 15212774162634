
import React from 'react'
import Link from "redux-first-router-link";
import { useAppThemeContext } from '../../../context';


const Brand = ()=>{

    const {theme, themeName} = useAppThemeContext()
    const boxesStyling = { backgroundColor: themeName == 'dark' 
    ? theme.colors.complimentary['accent-3'] 
    :  theme.colors.complimentary.base }
    const boxesStylingA = {backgroundColor: theme.colors.complimentary.foreground}
    const boxesStylingAccent = {backgroundColor: theme.colors.complimentary.base}
    const textColorStyles = {color:  theme.colors.complimentary.foreground}
    const textColorStylesAccent = {color: themeName == 'dark' ? theme.colors.primary.foreground : theme.colors.complimentary.foreground}

	

		return(


			<div className="app__brand" >

                <Link to={{ type: "HOME" }} className="app__brand--name">

                <span className="fg-dark d-inline-block " style={textColorStyles}>
                    jobbri
                </span>
                <span className="fg-dk-green mg-left-fd-bt d-inline-block font-fd-tn">
                    
                </span>



                </Link>

                
			</div>

		)


	
	

}




export default Brand;





