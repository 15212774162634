
import React,{Component} from 'react'
// import Job from './job/job'
// import Design from './design/design' 
// import Media from './media/media'
// import Made from './made/made'

import Brand from './brand/brand'
import Cta from './cta/cta'
import LaunchForm from '../launchform/launchform'

class Soon extends Component{
    
    
    render(){
        
        const {home,actions} = this.props

        return(

            <div className="home__soon" style={{zIndex:2}}>

               
                <Brand /> 
                <Cta>
                    <LaunchForm actions={actions} home={home} />
                </Cta>
                
            </div>
        )
    }

}


export default Soon