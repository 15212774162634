
import React, {Component} from 'react' 
import { ThemeContext } from '../../../context/index'

class Cta extends Component{

    constructor(props){
        super(props)
    }

    static contextType = ThemeContext
    render(){

        const {theme, themeName} = this.context
    const boxesStyling = { backgroundColor: themeName == 'dark' 
    ? theme.colors.complimentary['accent-3'] 
    :  theme.colors.complimentary.base }
    const boxesStylingA = {backgroundColor: theme.colors.complimentary.foreground}
    const boxesStylingAccent = {backgroundColor: theme.colors.complimentary.base}
    const textColorStyles = {color:  theme.colors.complimentary.foreground}
    const textColorStylesAccent = {color: themeName == 'dark' ? theme.colors.complimentary['accent-2'] : theme.colors.complimentary.foreground}
       

        return(

       

           
           <section className="home__soon--cta">

           <div className="home__soon--cta-rect home__soon--cta-rect1 bx-raised"></div>
           <div className="home__soon--cta-rect home__soon--cta-rect2 bx-raised"></div>
           <div className="home__soon--cta-rect home__soon--cta-rect3 bx-raised"></div>
           <div className="home__soon--cta-rect home__soon--cta-rect4 bx-raised" style={boxesStyling}>
               {this.props.children}
           </div>

       </section>
           
        )
    }




}

export default Cta