
import {combineReducers} from 'redux'
import { connectRoutes } from 'redux-first-router';
import { routePaths } from '../components/all/routes';
// import {connectRouter} from 'connected-react-router'
import history from '../history'

import allReducers from '../components/all/reducers'
const { reducer: location } = connectRoutes(routePaths);
// import routerReducer from '../components/ReduxFirstNavigator/reducer'




export default combineReducers({

    location,
    ...allReducers
   

})



// import { combineReducers } from 'redux';
// import { connectRoutes } from 'redux-first-router';
// import { routePaths } from '../routes';
// import user from './user/reducers';

// const { reducer: location } = connectRoutes(routePaths);

// const rootReducer = combineReducers({
//   location,
//   user
// });

// export default rootReducer;