import React from 'react';
 //const ExecutionEnvironment = require('exenv');

// import ReactDOM from 'react-dom';
// import { hydrate } from 'react-dom'
// import ReactDOM, { hydrate,render } from 'react-dom';
import { render, hydrate } from 'react-dom';
import { Provider } from 'react-redux'
// import * as central from './store/store'
// import {ConnectedRouter} from 'connected-react-router'
import createStoreAndHistory from './store/store'
// import {Router,Route} from 'react-router-dom'
import { toast } from 'react-toastify';
import { AppThemeContext } from './components/context/index';
// if(ExecutionEnvironment.canUseDOM){

  import 'react-toastify/dist/ReactToastify.css';
  import './notify.css'

  // import 'react-notifications/lib/notifications.css';

  // require('react-toastify/dist/ReactToastify.css');
  // require('./notify.css')

//}



 const {store,history} = createStoreAndHistory()
  // Call it once in your app. At the root of your app is the best place
toast.configure()


// export default (App)=>{

 

    // console.log('THE APP IS BEING RENDERD')
    // console.log(serverStore)
    // console.log(<App />)
  //  if(ExecutionEnvironment.canUseDOM){

  //    ReactDOM.hydrate(
  //       <Provider store={store}>
            
  //               <Router history={history}>
                
  //                 <Route component={App} />
                
  //               </Router>
            
  //       </Provider>, 
  //       document.getElementById('root')
  // )

   //}else{

  //   hydrate(
  //     <Provider store={central.store}>
          
  //             <Router history={central.history}>
              
  //               <Route Component={App} />
              
  //             </Router>
          
  //     </Provider>, 
  //     document.getElementById('root')
  // )

  //  }
  
     
      

//}

// export default (App)=>{

//      ReactDOM.render(
//             <Provider store={central.store}>
                
//                     <Router history={central.history}>
                    
//                       <Route component={App} />
                   
//                     </Router>
                
//             </Provider>, 
//             document.getElementById('root')
//         );

// }


export default (App)=>{


  const app = (

      <Provider store={store}>
          
            <App />
           
            
        
      </Provider>
  )


  const root = document.querySelector('#root')
   render(app,root);

//   if(typeof window !== 'undefined'){

//       hydrate(app,root)

//   }else{

//      render(app,root)
//   }
 }

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
