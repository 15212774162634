
import React from 'react' 
import {useAppThemeContext} from "../../../context/index"



function Brand() {

    const {theme, themeName} = useAppThemeContext()
    const boxesStyling = { backgroundColor: themeName == 'dark' 
    ? theme.colors.complimentary['accent-3'] 
    :  theme.colors.complimentary.base }
    const boxesStylingA = {backgroundColor: theme.colors.complimentary.foreground}
    const boxesStylingAccent = {backgroundColor: theme.colors.complimentary.base}
    const textColorStyles = {color:  theme.colors.complimentary.foreground}
    const textColorStylesAccent = {color: themeName == 'dark' ? theme.colors.primary.foreground : theme.colors.complimentary.foreground}
    

    return(

           

                

             

                    <section className="home__soon--brand">

                      <div className="home__soon--brand-logo">

                         <img src={themeName === "dark"  ? "/img/jo_white.png" : "/img/jobbrilogo.png" } alt="jobbri logo" />

                      </div>

                      <div className="home__soon--brand-text-s">

                          <h3 style={{...textColorStylesAccent,opacity: themeName === "dark" ? 1 : .8}}>Coming soon.</h3>
                          {/* <img src="https://media.giphy.com/media/8FuLBRxtL7nwCchUcd/giphy.gif" width="180" /> */}
                          
                      </div> 

                      <div className="home__soon--brand-text-vp">
                          
                          <h2 style={textColorStyles}>Find all local jobs tailor-made for you.</h2>
                          
                      </div>

                      <div className="home__soon--brand-text-cta">

                          <h2 style={textColorStyles}>Get vip invitation to our launch.</h2>
                         
                            <span className="home__soon--brand-cta-bar"></span>
                             
                      </div>
                     
                    </section> 

                   

           
               
               
           
    )

}


export default Brand