

import {createBrowserHistory, createMemoryHistory} from 'history' 

var ExecutionEnvironment = null
if(typeof window !== 'undefined'){

    ExecutionEnvironment = require('exenv');

}

export default (url)=>{

  let history = null 

  console.log('THE URL FROM THE SERVER PERHAPS')
  console.log(url)  

  ExecutionEnvironment && ExecutionEnvironment.canUseDom
    ? history = createBrowserHistory()
    : history = createMemoryHistory({
      initialEntries: [url]
    })


    return history
}


//  function runHistory(){
//     // Create a history depending on the environment
//     //   console.log('ExecuttionEnviromment')
 

//       const history = ExecutionEnvironment && ExecutionEnvironment.canUseDom
//         ? createBrowserHistory()
//         : createMemoryHistory({
//             initialEntries: ['/','/home','/404'],
//             initialIndex: 0
//         }) ;

//        return history
// }
// export default runHistory()