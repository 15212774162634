import React, {useState,useEffect} from "react";
import { darkTheme, lightTheme } from "../../theme/index";
const themes = {dark: darkTheme, light: lightTheme}

const  ThemeContext = React.createContext(null);

const AppThemeContext = (props) => {
  const themeNameFromStorage =  localStorage.getItem('APP_THEME_NAME') || 'light'
  const [currentThemeName, setCurrentThemeName] = useState(themeNameFromStorage)
  const [currentTheme, setCurrentTheme] = useState(themes[currentThemeName])
  const changeTheme = (name) => {
    setCurrentThemeName(name);
    localStorage.setItem('APP_THEME_NAME', name)
  };
  useEffect(() => {
    setCurrentTheme(themes[currentThemeName]);
  }, [currentThemeName]);
  

  return (
    <ThemeContext.Provider value={{ theme: currentTheme,themeName: currentThemeName,
      changeTheme,themes
  }}>
     {props.children}
    </ThemeContext.Provider>
  );
};

export default AppThemeContext;

const useAppThemeContext = () => {
  return React.useContext(ThemeContext);
};
export {ThemeContext, useAppThemeContext}
