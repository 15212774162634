
import React,{Component} from 'react'
import Dash from './dash/dash' 



class Jobdash extends Component{
    
    constructor(props){
        super(props)
    }
    
    render(){
        const {width} = this.props

        return(

            <div className="grid-row">

                
                <div >
                    <h2 className="font-fd-x-tn bg-primary-lta pd-fd-xx-sm  fg-primary mg-bottom-fd-x-bg pd-left-fl-x-bt home__jobdash--title">More Convenience for you</h2>
                     <Dash width={width} />
                  
                 
                </div>
               
                

            </div>
        )
    }

}


export default Jobdash