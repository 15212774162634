
import app from './app'
import init from './init'


export default function(){

     console.log('THE APP IS BOOTSTRAPPING')
    
     console.log(init)
     console.log(app)
     console.log('THE APP IS BOOTSRATPPING TWO')
     
     init(app)

}



