
import React from 'react' 
import Link from "redux-first-router-link";
import {useAppThemeContext} from "../../../context/index"



 function Dash (props){
    
    const {theme, themeName} = useAppThemeContext()
    const boxesStyling = { backgroundColor: themeName == 'dark' 
    ? theme.colors.complimentary['accent-3'] 
    :  theme.colors.complimentary.base }
    const boxesStylingA = {backgroundColor: theme.colors.complimentary.foreground}
    const boxesStylingAccent = {backgroundColor: theme.colors.complimentary.base}
    const textColorStyles = {color:  theme.colors.complimentary.foreground}
    const textColorStylesAccent = {color: themeName == 'dark' ? theme.colors.complimentary['accent-2'] : theme.colors.complimentary.foreground}
    const textPad = props?.width < 1200 ? {padding: "15px"} : {padding: 0}

    return(

      
           <section className="home__jobdash">

               <div className="home__jobdash--b1 " style={{...boxesStyling,...textPad}}>

                      <p className="font-fd-xx-tn" style={{...textColorStyles,backgroundColor:"transparent"}}>

                         Read simple stats and get notifications when new jobs arrive.
                         Our intuitive user interface makes it easier for you to manage 
                         your jobs.


                        </p>
               </div>

               <div className="home__jobdash--b2">

                   <section className="home__jobdash--b2-dash">

                    
                       <p className="font-fd-xx-tn fg-dark">

                            <img src="/img/userdash.jpg" alt="Jobbri User Dashbaord illustration"/>

                        </p>

                       

                   </section>

                   <section className="home__jobdash--b2-sign">
                   <a href={"#launch-form"} className="app__brand--name" style={{marginLeft:0}}>
                       <span className="font-fd-xx-tn">Get VIP Invite</span>
                    </a>
                   </section>
                    

                   

               </div>

              

               <strong className="clearfix" />
             
           </section>
      
    )

}


export default Dash