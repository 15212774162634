export default [

    {
      content: "Jobbri lets you search and apply for jobs from one place, you can upload resumes,and subscribe to job alerts",
      title: 'How does Jobbri works?',
    
    },
    {
        content: "Jobbri lets you search and apply for jobs from one place, you can upload resumes,and subscribe to job alerts",
        title: 'How does Jobbri works?',
      
    },
    {
        content: "Jobbri lets you search and apply for jobs from one place, you can upload resumes,and subscribe to job alerts",
        title: 'How does Jobbri works?',
      
    },

    {
        content: "Jobbri lets you search and apply for jobs from one place, you can upload resumes,and subscribe to job alerts",
        title: 'How does Jobbri works?',
      
    },
    {
    content: "Jobbri lets you search and apply for jobs from one place, you can upload resumes,and subscribe to job alerts",
    title: 'How does Jobbri works?',
    
    },
    {
    content: "Jobbri lets you search and apply for jobs from one place, you can upload resumes,and subscribe to job alerts",
    title: 'How does Jobbri works?',
    
    },
    {
    content: "Jobbri lets you search and apply for jobs from one place, you can upload resumes,and subscribe to job alerts",
    title: 'How does Jobbri works?',
    
    },
    {
    content: "Jobbri lets you search and apply for jobs from one place, you can upload resumes,and subscribe to job alerts",
    title: 'How does Jobbri works?',
    
    },
    {
    content: "Jobbri lets you search and apply for jobs from one place, you can upload resumes,and subscribe to job alerts",
    title: 'How does Jobbri works?',
    
    },
    
   
]