
import React,{Component} from 'react'
import { ThemeContext } from '../../context'




class Footer extends Component{
    
  
    static contextType = ThemeContext
    render(){

        const {theme, themeName} = this.context
    const boxesStyling = { backgroundColor: themeName == 'dark' 
    ? theme.colors.complimentary['accent-3'] 
    :  theme.colors.complimentary.base }
    const boxesStylingA = {backgroundColor: theme.colors.complimentary.foreground}
    const boxesStylingAccent = {backgroundColor: theme.colors.complimentary.base}
    const textColorStyles = {color:  theme.colors.complimentary.foreground}
    const textColorStylesAccent = {color: themeName == 'dark' ? theme.colors.complimentary['accent-2'] : theme.colors.complimentary.foreground}
        return(

            <div className="home__footer clearfix">

             

                   

                    <ul className="home__footer--list">
                        <li className="home__footer--list-i">
                            
                            <a href="https://www.facebook.com/Jobbri-105946767631202/" target="_blank">


                                <span className="home__footer--list-bx"></span>
                                <span className="home__footer--list-txt">Facebook</span>

                            </a>
                        </li> 
                        <li className="home__footer--list-i home__footer--list-adjust">
                            <a href="https://twitter.com/jobbrijobs" target="_blank">
                                <span className="home__footer--list-bx"></span>
                                <span className="home__footer--list-txt">Twitter</span>

                            </a>
                        </li>
                        <li className="home__footer--list-i">
                            <span className="home__footer--list-copy" style={textColorStyles}> &copy;  </span>
                            <img src={themeName === "dark"  ? "/img/jo_white.png" : "/img/jobbrilogo.png" } alt="Jobbri logo" />
                            <span style={textColorStyles}>{new Date().getFullYear()}</span>
                        </li>
                    </ul>                   


             
            </div>
        )
    }

}


export default Footer