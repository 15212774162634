

// import Home from '../Home/component'
// import Privacy from '../Privacy/component'

// import NotFound404 from '../Notfound404/component'
// import Error400 from '../Error400/component'
// import Error401 from '../Error401/component'
// import Error403 from '../Error403/component'
// import Error408 from '../Error408/component'
// import Error500 from '../Error500/component'
// import Error503 from '../Error503/component'

// export default [
//     {
//         component: Home,
//         name: 'Home',
//         path: '/'
//     },

//     {
//         component: Privacy,
//         name: 'privacy',
//         path: '/privacy'
//     },


//     {
//         component: Error400,
//         name: 'BadRequest',
//         path: '/400',
//         error: true
        
//     },
//     {
//         component: Error401,
//         name: 'Notfound',
//         path: '/401',
//         error: true
        
//     },
//     {
//         component: Error403,
//         name: 'Notfound',
//         path: '/403',
//         error: true
        
//     },
//     {
//         component: Error408,
//         name: 'Notfound',
//         path: '/408',
//         error: true
        
//     },
//     {
//         component: Error500,
//         name: 'Notfound',
//         path: '/500',
//         error: true
        
//     },
//     {
//         component: Error503,
//         name: 'Notfound',
//         path: '/503',
//         error: true
        
//     },
//     {
//         component: NotFound404,
//         name: 'Notfound',
        
//     },
   
    
    
    
// ]


export const routePaths = {

    'HOME': '/',
    'PRIVACY': '/privacy',
    "ERROR400": '/400',
    "NOT_FOUND": '/400'
    // 'PROFILE': { path: '/profile/:username', thunk: fetchUserData },
    // 'SHOWS': '/shows',
    // 'RECEPTION': '/shows/reception'
  }
