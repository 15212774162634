// import {applyMiddleware,createStore} from 'redux'
import { connectRoutes } from 'redux-first-router';
import { createStore, applyMiddleware, compose } from 'redux';
//const ExecutionEnvironment = require('exenv');
// import {routerMiddleware} from 'connected-react-router'
// import {reducer as notificationsReducer} from 'reapop';
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
// import history from '../history' 
import createHistory from '../history' 

// import {routerMiddleware} from '../middlwares/reduxroute'
import apiMiddleware from '../middlwares/api'
import {operationalErrorMiddleware} from '../middlwares/operationalError';

// import syncStoreHistoryWithBrowser from '../middlwares/history'
// import appRall from '../middlwares/rall'
import {routePaths} from '../components/all/routes'
import reducers from '../reducers/reducers'
// import startListener from '../utilities/listener'
// import {push} from '../middlwares/withcustommiddlewares' 
const {middleware: routerMiddleware,enhancer: routerEnhancer,initialDispatch
  } = connectRoutes(routePaths, { initialDispatch: false });


export default (url='/')=>{

    let store = null

    let history = createHistory(url)

    // if(ExecutionEnvironment.canUseDom){

    //     preloadedState = window.__PRELOADED_STATE__ 
    //     delete window.__PRELOADED_STATE__

    //     store = createStore(
    //         reducers,
    //         preloadedState,
    //         compose(
    //             routerEnhancer,
    //             applyMiddleware(thunk, routerMiddleware,apiMiddleware,operationalErrorMiddleware), 
    //             window.devToolsExtension ? window.devToolsExtension() : f => f
    //           )
            // composeWithDevTools(
            //     applyMiddleware(
            //         thunk,
            //         routerMiddleware(history),
            //         apiMiddleware,
            //         operationalErrorMiddleware
                  
            //         )
            // )
        //)
        
    //}else{
    
        console.log('THE STORE')
    
        store = createStore(
            reducers,
            compose(
                routerEnhancer,
                applyMiddleware(thunk, routerMiddleware,apiMiddleware,operationalErrorMiddleware), 
                window.devToolsExtension ? window.devToolsExtension() : f => f
              )
            )
    
    
    
    //}


// startListener(history,store)
initialDispatch();

return {
    store,
    history
}


}

// let store = null
// const catchHistoryActionCreators = routerMiddleware(history)



 



