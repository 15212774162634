
import React from 'react' 
import Root from './components/Root/component'
import { AppThemeContext } from './components/context'



export default ()=>{

    return (
      <AppThemeContext>
        <Root />
      </AppThemeContext>
       
    )

}



