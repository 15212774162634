
import home from '../Home/reducer'
import privacy from '../Privacy/reducer'
// import navigator from '../Navigator/reducer'
// import history from '../History/reducer'
import auth from '../Auth/reducer'
import errorHandler from '../ErrorHandler/reducer'


export default {
    home,
    privacy,
    auth,
    errorHandler

}