// import { Box, Button, Heading, Paragraph } from "grommet";
import React, { Component, useState } from "react";
import Select from "react-select";
import { useAppThemeContext, ThemeContext } from "../context/index";
import Brightness4Icon from '@material-ui/icons/Brightness4';
import ToggleOffIcon from '@material-ui/icons/ToggleOff';
import Icon from '@material-ui/core/Icon';
// import { themes } from "../../config/themes";

// const options = [
//   { value: "en", label: "English" },
//   { value: "ts", label: "Tsonga" },
//   { value: "ve", label: "Venda" },
// ];

class ThemeSwitcher extends Component {
   constructor(props){
    super(props)
    this.switchTheme = this.switchTheme.bind(this)
   }
   static contextType = ThemeContext
//   const { changeTheme, theme, themes, themeName } = useAppThemeContext();
//   const [selectedOption, setSelectedOption] = useState(theme);
//   console.log("the themSWITCHER;;;", themes);
//   console.log(changeTheme, theme);

componentDidMount(){
    console.log("THE CONTEXT", this.context)
}

  switchTheme (value) {
    console.log("the props;;", value);
    let valueUpdated = value === 'light' ? 'dark' : 'light'
    this.context.changeTheme(valueUpdated);
    // setSelectedOption(value);
  };

//   const getOptions = () => {
//     const optionDictionary = [];
//     for (let themeName in themes) {
//       console.log("the theme I;;", themeName);
//       optionDictionary.push({ value: themes[themeName], label: themeName });
//     }
//     return optionDictionary;
//   };

//   const customStyles = {
//     menu: (provided, state) => ({
//       ...provided,
//       width: state.selectProps.width,
//       borderBottom: "1px dotted pink",
//       color: state.selectProps.menuColor,
//       padding: 20,
//     }),

//     option: (provided, state) => ({
//       ...provided,
//       borderBottom: "1px dotted pink",
//       color: state.isSelected ? "red" : "blue",
//       padding: 20,
//     }),

//     control: (_, { selectProps: { width } }) => ({
//       width: width,
//     }),

//     singleValue: (provided, state) => {
//       const opacity = state.isDisabled ? 0.5 : 1;
//       const transition = "opacity 300ms";

//       return { ...provided, opacity, transition };
//     },
//   };

static contextType = ThemeContext
render(){

    const {theme, themeName} = this.context
const textColorStyles = {color:  theme.colors.complimentary.foreground}
const textColorStylesAccent = {color: themeName == 'dark' ? theme.colors.complimentary['accent-2'] : theme.colors.complimentary.foreground}
    
    return (
    
        <Icon onClick={()=>this.switchTheme(this.context.themeName)}>
             {/* <Brightness4Icon style={styles.root} className="fg-primary" /> */}
             <p style={{display:"flex", flexDirection:"row",alignItems:"center"}}>
             <span style={{...textColorStyles, fontWeight: "bold"}}>{themeName === "dark" ? "Dark" : "Light"}</span>
             <ToggleOffIcon style={styles.root} className="fg-primary" />
             </p>
             
        </Icon>
       
    
   );
  }
 
};

const styles = {
    root: {
     
    //   color:"#ff6633",
      fontSize: 60,
      
    },
    iconhover:{
        '&:hover':{

            content: ""
        }
    }
  };

export default ThemeSwitcher;
